<template>
  <div>
    <div class="index-bg relative overflow-hidden">
      <div class="index-bg-shadow"></div>
      <div class="cricle-bg-animation sm:!h-[1000px] sm:!w-[900px]"></div>
      <!-- 核心优势 -->
      <section class="w-1440 mb-[40px] relative" id="businessAdvantages">
        <p class="section-title pl-88 pt-80 mb-[24px] fade-in-up">
          核心优势
        </p>
        <p class="section-subTitle mb-[48px] px-88 sm:mb-[24px] fade-in-up">
          行业领先的产业增长能力，为开发者提供一站式增长赋能
        </p>
        <div class="advantages px-88 relative fade-in-up sm:!px-0">
          <div
            class="advantages-nav-list-holder h-[91px] md:h-[61px] sm:!h-[36px] sm:!mb-[16px]"
          ></div>
          <div
            class="advantages-nav-list px-88 absolute top-0 left-0 sm:!hidden"
          >
            <div
              v-for="(item, index) in PCData.advantages"
              :key="item.title"
              class="nav-item h-[91px] text-[19px] min-w-[234px] md:h-[61px] md:text-[16px] md:min-w-[171px] sm:!h-[36px] sm:!text-[12px]  sm:!min-w-[120px]"
              :class="{
                active: activeAdvantagesIndex === index,
              }"
              @click="handleNavClick(index)"
            >
              <img
                class="icon md:hidden"
                :src="activeAdvantagesIndex === index ? item.icon1 : item.icon2"
                alt=""
              />
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div
            class="advantages-nav-list px-88 absolute top-0 left-0  !hidden sm:!flex"
          >
            <div
              v-for="(item, index) in PCData.advantages"
              :key="item.title"
              class="nav-item h-[91px] text-[19px] min-w-[234px] md:h-[61px] md:text-[16px] md:min-w-[171px] sm:!h-[36px] sm:!text-[12px]  sm:!min-w-[120px]"
              :class="{
                active: activeMdAdvantagesIndex === index,
              }"
              @click="handleNavClick(index)"
            >
              <img
                class="icon md:hidden"
                :src="
                  activeMdAdvantagesIndex === index ? item.icon1 : item.icon2
                "
                alt=""
              />
              <span>{{ item.title }}</span>
            </div>
          </div>
          <swiper
            class="swiper fade-in-up sm:hidden"
            ref="advantagesSwiper"
            :options="advantagesSwiperOptions"
          >
            <swiper-slide
              v-for="item in PCData.advantages"
              :key="item.title"
              class="swiperItem"
            >
              <div class="advantages-content">
                <div class="desc md:!pr-[215px]">
                  <p class="title">{{ item.title }}</p>
                  <div class="desc-list">
                    <div
                      class="list-item"
                      v-for="desc in item.descList"
                      :key="desc.subtitle"
                    >
                      <p class="subtitle">
                        <img class="dec-icon" :src="desc.icon" alt="" />
                        <span>{{ desc.subtitle }}</span>
                      </p>
                      <p class="desc-text">{{ desc.desc }}</p>
                    </div>
                  </div>
                </div>
                <div
                  class="img-container md:!w-[521px] md:!absolute md:!top-[-165px] md:!right-[-100px] md:!opacity-50"
                >
                  <img class="img" :src="item.img" :alt="item.title" />
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <swiper
            class="sm-swiper fade-in-up hidden sm:block"
            ref="mdAdvantagesSwiper"
            :options="mdAdvantagesSwiperOptions"
          >
            <swiper-slide
              v-for="item in PCData.advantages"
              :key="item.title"
              class="swiperItem"
            >
              <div class="advantages-content">
                <div class="desc">
                  <p class="title">{{ item.title }}</p>
                  <div class="desc-list">
                    <div
                      class="list-item"
                      v-for="(desc, descIndex) in item.descList"
                      :key="desc.subtitle"
                      :style="descIndex > 2 ? { marginBottom: '16px' } : {}"
                    >
                      <p class="subtitle">
                        <img class="dec-icon" :src="desc.icon" alt="" />
                        <span>{{ desc.subtitle }}</span>
                      </p>
                      <p
                        v-if="!(item.title == '太极AIGC引擎' && descIndex > 2)"
                        class="desc-text"
                      >
                        {{ desc.desc }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="img-container">
                  <img class="img" :src="item.img" :alt="item.title" />
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="advantages-swiper-pagination hidden sm:block"></div>
        </div>
      </section>
      <!-- 业务成果 -->
      <section class="w-1440" id="businessAchievement">
        <p class="section-title pl-88 pt-80 mb-[270px] sm:mb-[24px] fade-in-up">
          业务成果
        </p>
        <div class="achievement px-88 fade-in-up">
          <div
            class="achievement-content sm:!flex flex-col sm:!h-auto sm:!mb-[48px]"
            ref="indexExtenet"
          >
            <div
              class="achievement-item sm:h-[120px] sm:px-[22px] sm:py-[36px] sm:!flex-row sm:!item-end sm:!justify-between sm:!border-0 sm:even:!border-b-[1px] sm:even:!border-t-[1px]"
              v-for="(item, index) in PCData.extenets"
              :key="item.txtXContent"
            >
              <p class="num-content">
                <ICountUp
                  class="num sm:!text-[48px] sm:!leading-[48px] sm:!mb-0 mr-[4px]"
                  :id="'num' + (index + 1)"
                  :start="0"
                  :endVal="endValArr[index]"
                  :options="num1Options"
                  :delay="1000"
                  :speed="1"
                />
                <span
                  class="unit sm:!text-[24px] sm:!leading-[24px] sm:after:!translate-y-[-15px]"
                  data-unit="+"
                  >{{ item.isShowYiTxt ? "亿" : "" }}</span
                >
              </p>
              <div class="desc">{{ item.txtXContent }}</div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- 开发者成功案例 -->
    <section class="relative" id="businessCase">
      <div class="absolute h-[827px] w-full top-0 left-0 flex md:!hidden">
        <div class="w-[50%] bg-[#fafafa]"></div>
        <div></div>
      </div>
      <section class="hidden md:block w-1440 mb-[40px] relative">
        <p
          class="section-title pl-88 pt-[120px] sm:pt-[48px] mb-[24px] sm:mb-[4px] fade-in-up"
        >
          开发者成功案例
        </p>
        <p class="section-subTitle mb-[48px] sm:mb-[32px] pl-88 fade-in-up">
          完善的开发者孵化机制，与开发者共创共赢
        </p>
      </section>
      <div class="case w-1440 md:!flex md:flex-col md:!h-auto sm:mb-[48px]">
        <div class="relative">
          <swiper
            class="swiper fade-in-up h-[827px] md:h-[368px] sm:!h-[214px]"
            ref="caseSwiper"
            :options="caseSwiperOptions"
          >
            <swiper-slide
              v-for="item in PCData.successCase"
              :key="item.title"
              class="swiperItem"
            >
              <div class="md:hidden">
                <div class="img-container grid grid-cols-3">
                  <div
                    v-for="img in importAll(item.imgList).slice(0, 9)"
                    :key="img"
                    class="flex items-center justify-center img-item"
                  >
                    <img
                      class="w-[120px] h-[120px]"
                      :src="img"
                      :alt="item.title"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full h-full hidden md:block">
                <div
                  class="marquee-left px-[48px] sm:px-[20px] mb-[38px] pt-[55px] sm:pt-[32px] sm:mb-[22px]"
                >
                  <div
                    v-for="img in importAll(item.imgList).slice(0, 5)"
                    :key="img"
                    class="flex items-center justify-center mr-[93px] sm:mr-[20px]"
                  >
                    <img
                      class="min-w-[110px] h-[110px] sm:min-w-[64px] sm:h-[64px]"
                      :src="img"
                      :alt="item.title"
                    />
                  </div>
                  <div
                    v-for="(img, index) in importAll(item.imgList).slice(0, 5)"
                    :key="index"
                    class="flex items-center justify-center mr-[93px] sm:mr-[20px]"
                  >
                    <img
                      class="min-w-[110px] h-[110px] sm:min-w-[64px] sm:h-[64px]"
                      :src="img"
                      :alt="item.title"
                    />
                  </div>
                </div>
                <div class="marquee-right px-[48px] sm:px-[20px]">
                  <div
                    v-for="img in importAll(item.imgList).slice(-5)"
                    :key="img"
                    class="flex items-center justify-center mr-[93px] sm:mr-[20px]"
                  >
                    <img
                      class="min-w-[110px] h-[110px] sm:min-w-[64px] sm:h-[64px]"
                      :src="img"
                      :alt="item.title"
                    />
                  </div>
                  <div
                    v-for="(img, index) in importAll(item.imgList).slice(-5)"
                    :key="index"
                    class="flex items-center justify-center mr-[93px] sm:mr-[20px]"
                  >
                    <img
                      class="min-w-[110px] h-[110px] sm:min-w-[64px] sm:h-[64px]"
                      :src="img"
                      :alt="item.title"
                    />
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-scrollbar"></div>
        </div>
        <div class="case-nav-list fade-in-up md:!hidden">
          <p class="section-title mb-[24px]">
            开发者成功案例
          </p>
          <div class="section-subTitle mb-[48px]">
            完善的开发者孵化机制，与开发者共创共赢
          </div>
          <div
            v-for="(item, index) in PCData.successCase"
            :key="item.title"
            class="nav-item"
            :class="{ active: activeCaseIndex === index }"
            @mouseover="handleCaseNavClick(index)"
          >
            <p class="nav-title">{{ item.title }}</p>
            <div class="nav-content" v-show="activeCaseIndex === index">
              <p class="desc">{{ item.desc }}</p>
              <p>里程碑：</p>
              <p v-for="marker in item.markerList" :key="marker">
                <span>{{ marker }}</span>
              </p>
            </div>
          </div>
        </div>
        <div
          class="md:block hidden"
          :style="{
            backgroundColor: PCData.successCase[activeCaseIndex].bgColor,
          }"
        >
          <div class="md-case-nav-list sm:!h-[48px]">
            <div
              v-for="(item, index) in PCData.successCase"
              :key="item.title"
              class="nav-item sm:!h-[48px] sm:!text-[16px] sm:!leading-[48px]"
              :class="{ active: activeCaseIndex === index }"
              @mouseover="handleCaseNavClick(index)"
              :style="{
                backgroundColor: item.bgColor,
              }"
            >
              {{ item.title }}
            </div>
          </div>

          <div
            class="nav-content pt-[12px] pb-[32px] px-88"
            :style="{
              backgroundColor: PCData.successCase[activeCaseIndex].bgColor,
            }"
          >
            <p
              class="desc sm:!text-[14px] sm:!leading-[28px] sm:!mb-[16px] sm:!opacity-70"
            >
              {{ PCData.successCase[activeCaseIndex].desc }}
            </p>
            <p class="sm:text-[17px]">里程碑：</p>
            <p
              v-for="marker in PCData.successCase[activeCaseIndex].markerList"
              :key="marker"
              class="sm:!text-[14px] sm:!leading-[28px]"
            >
              <span>{{ marker }}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import pageData from "../../data/indexData";
import lottie from "lottie-web";
import utils from "utils/utils";
export default {
  name: "Summary",
  components: {
    ICountUp,
  },
  data() {
    return {
      PCData: {},
      //核心优势
      activeAdvantagesIndex: 0,
      activeMdAdvantagesIndex: 0,
      advantagesSwiperOptions: {
        direction: "horizontal",
        effect: "none",
        loop: false,
        autoplay: false,
        speed: 500,
        autoplayDisableOnInteraction: false,
        observer: true,
        on: {
          slideChange: () => {
            //控制幻灯片滑动的动画
            this.activeAdvantagesIndex = this.$refs.advantagesSwiper.swiper.realIndex;
          },
        },
      },
      mdAdvantagesSwiperOptions: {
        autoHeight: false,
        slidesPerView: "auto",
        spaceBetween: 12,
        centeredSlides: true,
        loop: true,
        speed: 500,
        autoplayDisableOnInteraction: false,
        observer: true,
        on: {
          slideChange: () => {
            //控制幻灯片滑动的动画
            this.activeMdAdvantagesIndex = this.$refs.mdAdvantagesSwiper.swiper.realIndex;
          },
        },
        pagination: {
          el: ".advantages-swiper-pagination",
          clickable: true,
        },
      },
      endValArr: [0, 0, 0],
      newEndValArr: [120, 2, 52],
      num1Options: {
        useEasing: true,
        useGrouping: true,
      },
      firstCount: true,
      activeCaseIndex: 0,
      caseSwiperOptions: {
        autoHeight: false,
        direction: "vertical",
        effect: "none",
        loop: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        speed: 500,
        autoplayDisableOnInteraction: false,
        observer: true,
        on: {
          slideChange: () => {
            //控制幻灯片滑动的动画
            this.activeCaseIndex = this.$refs.caseSwiper.swiper.realIndex;
          },
        },
        scrollbar: {
          el: ".swiper-scrollbar",
        },
      },
    };
  },
  created() {
    this.PCData = pageData.PCData;
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true);
    lottie.loadAnimation({
      container: document.getElementsByClassName("cricle-bg-animation")[0], // the dom element that will contain the animation
      renderer: "canvas",
      loop: true,
      autoplay: true,
      path:
        process.env.NODE_ENV !== "production"
          ? "./json/cricle_bg.json"
          : "/json/cricle_bg.json",
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandle); // 清除滚动监听
  },
  methods: {
    scrollHandle() {
      this.startCountNum();
    },
    importAll(r) {
      return r.keys().map(r);
    },
    handleNavClick(index) {
      console.log(index);

      this.activeAdvantagesIndex = index;
      this.activeMdAdvantagesIndex = index;
      this.$refs.advantagesSwiper.swiper.slideToLoop(index);
      this.$refs.mdAdvantagesSwiper.swiper.slideToLoop(index);
    },
    // 计数动画
    startCountNum() {
      const element = this.$refs.indexExtenet;
      if (utils.isElementInViewport(element) && this.firstCount) {
        this.firstCount = false;
        this.endValArr = this.newEndValArr;
      }
    },
    handleCaseNavClick(index) {
      this.activeCaseIndex = index;
      this.$refs.caseSwiper.swiper.slideToLoop(index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
